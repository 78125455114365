@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

/* reset css */
* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  text-decoration: none;
}

button {
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}
a{
  cursor: pointer;
}
/* reset css end */



/* Variables */
:root {
  --ftfamily: "Inter", sans-serif;
  /* colors */
  --primary: rgba(109, 57, 152, 1);
  --primary1: #8A749D;
  --primary2: rgba(248, 239, 255, 1);
  --light1: rgba(226, 215, 234, 1);
  --light2: rgba(202, 184, 218, 1);
  --dark1: rgba(65, 34, 91, 1);
  --dark2: rgba(22, 11, 30, 1);
  --dark3: rgba(65, 34, 91, 0.7);
  --black: rgba(0, 0, 0, 1);
  --gray1: rgba(223, 223, 223, 1);
  --gray2: rgba(128, 128, 128, 1);
  --gray3: rgba(64, 64, 64, 1);
  --white: rgba(255, 255, 255, 1);
  font-size: 10px;

  /* font sizes */
  --ft10: 1rem;
  --ft12: 1.2rem;
  --ft14: 1.4rem;
  --ft15: 1.5rem;
  --ft16: 1.6rem;
  --ft18: 1.8rem;
  --ft19: 1.9rem;
  --ft20: 2rem;
  --ft21: 2.1rem;
  --ft22: 2.2rem;
  --ft23: 2.3rem;
  --ft24: 2.4rem;
  --ft25: 2.5rem;
  --ft26: 2.6rem;
  --ft27: 2.7rem;
  --ft28: 2.8rem;
  --ft30: 3rem;
  --ft34: 3.4rem;
  --ft40: 4rem;
  --ft46: 4.6rem;
  --ft48: 4.8rem;
  --ft54: 5.4rem;

  /* font weight */
  --ft-wt100: 100;
  --ft-wt200: 200;
  --ft-wt300: 300;
  --ft-wt400: 400;
  --ft-wt500: 500;
  --ft-wt600: 600;
  --ft-wt700: 700;
  --ft-wt800: 800;
  --ft-wt900: 900;
}

/* reset css */

body {
  font-family: var(--ftfamily);
  /* font-size: var(--ft14); */
  background-color: var(--white);
}

.dsk-show {
  display: block;
}

.mob-show {
  display: none;
}

.pos-relative {
  position: relative;
  z-index: 0;
}

.fw_500 {
  font-weight: var(--ft-wt500);
}

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem;
  justify-content: space-between;
}

.left_col {
  width: calc(50% - 1.5rem);
}

.right_col {
  width: calc(50% - 1.5rem);
}

.icon_contianer {
  display: inline-block;
}

.container {
  max-width: 1400px;
  padding: 0 1.5rem;
  margin: auto;
}

.img_responsive {
  max-width: 100%;
}

.main_wrapper {
  max-width: 100%;
  background-color: var(--white);
  overflow: hidden;
  padding-top: 10rem;
}

.btn_dark {
  background-color: var(--primary);
  color: var(--white);
  font-size: var(--ft16);
  font-weight: var(--ft-wt600);
  letter-spacing: -0.01em;
  border-radius: 0.6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  transition: 0.25s;
}

.btn_dark:hover {
  background-color: var(--dark1);
}

.btn_white_border {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: var(--ft15);
  font-weight: var(--ft-wt700);
  color: var(--white);
  border: 0.1rem solid var(--white);
  border-radius: 2rem;
  min-width: 15.8rem;
  min-height: 4rem;
  text-decoration: none;
}

.bg_primary--blend {
  position: absolute;
  rotate: 180deg;
  background: linear-gradient(180deg, #FFFFFF 0%, #F8EFFF 100%);
  bottom: 0rem;
  left: 0;
  right: 0;
  height: 10rem;
  z-index: -1;
}

.bg_white_top--blend {
  position: absolute;
  background: linear-gradient(180deg, #FFFFFF 0%, #F8EFFF 100%);
  top: 0rem;
  left: 0;
  right: 0;
  height: 10rem;
  z-index: -1;
}

.main_heading {
  font-size: var(--ft48);
  color: var(--dark1);
  font-weight: var(--ft-wt500);
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.main_heading .highlight_text {
  display: inline-block;
  background-image: url('../public/assets/img/highlight_text.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0;
  font-weight: var(--ft-wt700);
  padding: 1rem;
}


/* header css start */
.site_header {
  background-color: var(--primary2);
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 5rem;
  z-index: 3;
  box-shadow: 0 0 0rem rgba(0, 0, 0, .1);
  transition: 0.25s;
}

.site_header.fixed {
  /* background-color: var(--white); */
  box-shadow: 0 0 1rem rgba(0, 0, 0, .1);
}

.site_header nav {
  display: flex;
  padding: 3rem;
  align-items: center;
  justify-content: space-between;
}

.site_header nav .link {
  font-weight: var(--ft-wt600);
  font-size: var(--ft15);
  margin-right: 3.2rem;
  text-decoration: none;
  color: var(--dark1);
}

.site_header nav .header_links_sec {
  max-width: calc(100% - 10rem);
}

.header_links_sec .btn_dark {
  min-height: 4rem;
  min-width: 14rem;
}

.toggler {
  position: relative;
  width: 3rem;
  height: 2rem;
  display: none;
}

.toggler i {
  display: block;
  height: 0.4rem;
  width: 100%;
  background-color: var(--primary);
  position: absolute;
  visibility: visible;
  opacity: 1;
  transition: 0.25s ease-in;
}

.toggler i:first-child {
  top: 0;
}

.toggler i:nth-of-type(2n) {
  top: 50%;
  translate: 0 -50%;
}

.toggler i:last-child {
  bottom: 0;
}

.toggler.active i:nth-of-type(2n) {
  visibility: hidden;
  opacity: 0;
}

.toggler.active i:first-child {
  top: 50%;
  translate: 0 -50%;
  rotate: 45deg;
}

.toggler.active i:last-child {
  top: 50%;
  translate: 0 -50%;
  rotate: -45deg;
}

/* header css end */
/* hero section css start  */
.hero_section {
  background-color: var(--primary2);
  padding-bottom: 100px;
}

.hero_section .row {
  justify-content: space-between;
  gap: 3rem;
}

.hero_section .title {
  font-size: var(--ft40);
  line-height: 5.4rem;
  color: var(--dark1);
  font-weight: var(--ft-wt700);
  margin-bottom: 1.8rem;
}

.hero_section .sub_title {
  font-size: var(--ft20);
  line-height: 150%;
  color: var(--dark3);
  font-weight: var(--ft-wt400);
  margin-bottom: 1.8rem;
}

.hero_section .sub_title strong {
  color: var(--primary);
  font-weight: var(--ft-wt500);
}

.hero_section .btn_dark {
  min-width: 20rem;
  min-height: 5.4rem;
  margin-top: 4.8rem;
}

.hero_section .left_col {
  max-width: 50rem;
}

.hero_section .right_col {
  text-align: right;
}

/* hero section css end  */

.problem_section {
  padding-bottom: 100px;
}

.problem_section .main_heading {
  margin-bottom: 8rem;
}

.problem_section .oval_div {
  position: relative;
  z-index: 0;
  display: inline-block;
  padding-right: 1.5rem;
}

.problem_section .oval_div::before {
  content: '';
  position: absolute;
  width: 170%;
  height: 130%;
  background: linear-gradient(270deg, rgba(231, 194, 255, 0.3) 0%, rgba(242, 222, 255, 0.3) 76.04%);
  border-radius: 50%;
  right: 2%;
  top: 50%;
  translate: 0 -50%;
  z-index: -1;
}

.problem_section .oval_div::after {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  background: radial-gradient(49.9% 69.87% at 49.9% 50.1%, rgba(209, 137, 255, 0.8) 0%, rgba(218, 162, 255, 0.184) 100%);
  filter: blur(50px);
  border-radius: 50%;
  left: 5%;
  top: -18%;
  z-index: -1;
}

.problem_section .problem_img {
  border-radius: 50%;
}

.problem_section .title {
  font-size: var(--ft40);
  font-weight: var(--ft-wt600);
  line-height: 5.8rem;
  color: var(--dark1);
  margin-bottom: 1.1rem;
}

.problem_section .desc {
  font-size: var(--ft20);
  font-weight: var(--ft-wt400);
  line-height: 150%;
  color: var(--dark3);
  margin-bottom: 1.1rem;
  padding-right: 1rem;
}

/* problem section css end */
.solution_section .main_heading {
  margin-bottom: 7rem;
}

.solution_section .distributor_row {
  margin-top: 10rem;
}

.solution_section .main_heading .highlight_text {
  padding: 1rem 1rem 1rem 2rem;
}

.solution_section .bg_dots {
  position: absolute;
  top: 0;
  left: -8rem;
  z-index: -1;
  width: 100%;
}

.solution_section .right_col .bg_dots {
  left: 0;
}

.solution_section .show_hover_card {
  margin-top: 8rem;
  min-height: 47.3rem;
  background-color: var(--white);
  border: 0.06rem solid #CAB8DA;
  box-shadow: 0px 0px 2rem rgba(0, 0, 0, 0.25);
  border-radius: 1.2rem;
  max-width: 45rem;
  padding: 2.4rem;
  font-size: var(--ft20);
  line-height: 2.6rem;
  color: var(--dark1);
}

.solution_section .right_col .show_hover_card {
  margin-left: 10rem;
}

.solution_section .show_hover_card .card_title {
  border-left: 0.8rem solid var(--primary);
  padding: 1.2rem;
  font-size: var(--ft20);
  font-weight: var(--ft-wt600);
  color: var(--primary);
}

.show_hover_card .icon_contianer {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
}

.show_hover_card .icon_contianer svg {
  width: 14rem;
  height: 14rem;
}

.show_hover_card .icon_contianer svg path {
  fill: var(--primary)
}

.solution_section .title {
  font-size: var(--ft34);
  font-weight: var(--ft-wt500);
  margin-bottom: 2rem;
  color: var(--primary);
}

.solution_section .text {
  color: var(--dark1);
  font-size: var(--ft20);
  line-height: 150%;
}

.solution_section .hover_card_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
  margin-top: 5rem;
}

.solution_section .hover_card {
  width: calc(33% - 1.67rem);
  min-height: 18rem;
  border: 0.1rem solid var(--light2);
  border-radius: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  cursor: pointer;
  transition: 0.2s;
  color: var(--dark1);
  font-size: var(--ft18);
  line-height: 150%;
  transition: 0.25s;
  background-color: var(--white);
}

.solution_section .hover_card_title {
  text-align: center;
  font-weight: 500;
  font-size: var(--ft20);
}

.hover_card.active {
  border-color: var(--primary);
}

.hover_card .icon_contianer path {
  transition: 0.2s;
}

.hover_card.active .icon_contianer path {
  fill: var(--primary);
}

/* solution section css end */

.ecosystem_solution {
  margin-top: 10rem;
}

.ecosystem_solution .main_heading {
  margin-bottom: 6.7rem;
}

/* ecosystem section css end */
.doco_part_section {
  padding-top: 15rem;
  padding-bottom: 5rem;
  background-color: var(--primary2);
}

.doco_part_section .main_heading {
  margin-bottom: 5rem;
}

.doco_part_card_wrapper {
  display: flex;
  flex-direction: column;
}

.doco_part_card {
  width: 85%;
  padding:1.5rem 2rem;
  background-color: var(--white);
  border: 0.1rem solid var(--light1);
  border-radius: 1.6rem;
  clear: both;
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
}

.doco_part_card:nth-of-type(2n) {
  margin-left: auto;
}

.doco_part_card .doco_img_div {
  width: 10.8rem;
  /* min-height: 12rem; */
}

.doco_part_card .doco_img_div img {
  max-width: 100%;
}

.doco_part_card .doco_card_text_div {
  padding-left: 3.5rem;
  flex: 1;
}

.doco_part_card .card_title {
  font-size: var(--ft20);
  color: var(--primary);
  font-weight: var(--ft-wt600);
  margin-bottom: 1.2rem;
}

.doco_part_card .card_list {
  font-size: var(--ft16);
  color: var(--primary);
  font-weight: var(--ft-wt400);
  line-height: 2.2rem;
  padding-left: 3.5rem;
}

.doco_part_card .card_btn_div {
  margin-top: auto;
}

.doco_part_card .btn_dark {
  min-height: 5.4rem;
  min-width: 20rem;
}

/* doco_part_section css end */

.testimonials_section {
  margin-top: 12rem;
  margin-bottom: 17rem;
}

.testimonials_section .main_heading {
  margin-bottom: 10rem;
}

.testimonials_card_wrap {
  padding: 0 2rem 2rem 0rem;
}

.testimonials_card {
  border: 0.06rem solid var(--light2);
  border-radius: 1.2rem;
  min-height: 22rem;
  padding: 1.6rem;
  color: var(--dark1);
  font-weight: var(--ft-wt400);
  font-size: var(--ft16);
  color: var(--dark1);
  line-height: 2.2rem;
}

.testimonials_card .card_avatar_section {
  display: flex;
}

.testimonials_card .card_avatar_section .avatar_text {
  padding-left: 1.6rem;
}

.testimonials_card .card_avatar_section h2 {
  font-size: var(--ft20);
  font-weight: var(--ft-wt500);
  margin-top: 1.2rem;
  margin-bottom: 0.4rem;
}

.testimonials_card .avatar_img_div {
  display: inline-block;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  overflow: hidden;
}

.testimonials_card .avatar_img_div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonials_card .avatar_info {
  text-indent: 12rem;
  padding-top: 1rem;
}

.swiper-scrollbar {
  height: 1rem !important;
}

/* testimonials_section css end */
.footer_section {
  background-color: var(--primary);
  min-height: 100px;
  color: var(--white);
  padding-top: 6.6rem;
  padding-bottom: 6.6rem;
}

.footer-sec_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.footer_sec_1 {
  width: calc(40% - 1rem);
}

.footer_sec_2 {
  width: calc(20% - 1rem);
}

.footer_sec_3 {
  width: calc(20% - 1rem);
}

.footer_sec_4 {
  width: calc(20% - 1rem);
}

.footer_logo_link {
  margin-bottom: 3rem;
  display: inline-block;
}

.footer_dull_text {
  font-size: var(--ft16);
  color: var(--white);
  line-height: 140%;
  opacity: 0.6;
  max-width: 35rem;
  font-weight: 400;
  margin-bottom: 3.4rem;
}

.footer_dull_text p {
  margin-bottom: 2rem;
}

.footer_text_copyright {
  font-size: var(--ft12);
  margin-top: 4rem;
}


/* footer section 1 end */

.footer_section .footer_link_title {
  font-size: var(--ft18);
  font-weight: var(--ft-wt700);
  line-height: 2.2rem;
  opacity: 0.4;
  margin-bottom: 1rem;
}

.ul_unset {
  list-style: none;
  padding-left: 0;
}

.ul_unset li {
  margin-bottom: 1rem;
}

.footer_link {
  text-decoration: none;
  font-size: var(--ft14);
  color: var(--white);
}


.footer_section .contact_sec_p {
  display: flex;
  text-decoration: none;
  font-size: var(--ft14);
  color: var(--white);
}

.footer_section .contact_sec_p .p_title {
  width: 8rem;
}

.footer_section .contact_sec_p .p_value {
  width: calc(100% - 8rem);
}

.footer_section .footer_social_icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5rem;
}

.social_icon_link {
  margin-left: 3rem;
  display: inline-flex;
}


/* dodco life page css start here */
.docolife_hero_section .left_col {
  max-width: 52rem;
  display: flex;
  align-items: center;
}

/* what_you_get_section  css start*/
.what_you_get_section .main_heading {
  margin-bottom: 7rem;
}

.what_you_get_section .accordion_btn {
  width: 100%;
  background: var(--white);
  padding: 1.3rem 1.6rem;
  border-radius: 0.4rem;
  color: var(--dark1);
  font-size: var(--ft20);
  font-weight: var(--ft-wt400);
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  transition: 0.25s ease-in;
  margin-bottom: 0.6rem;
}

.what_you_get_section .accordion_btn.mob-show {
  display: none !important;
}

.what_you_get_section .accordion_btn:hover {
  background-color: var(--light1);
}

.what_you_get_section .accordion_btn.active {
  background-color: var(--primary);
  color: var(--white);
  font-weight: var(--ft-wt600);
}

.what_you_get_section .accordion_btn .down_angle {
  display: none;
  transition: 0.25s ease-in;
}

.what_you_get_section .accordion_btn.active .down_angle {
  rotate: 180deg;
}

.what_you_get_section .accordion_btn.active .down_angle svg path {
  fill: var(--white);
}

.what_you_get_section .accordion_btn span {
  flex: 1;
}

.what_you_get_section .collapsable {
  display: none;
  font-size: var(--ft16);
  color: var(--dark1);
}

.what_you_get_section .collapsable p {
  margin-top: 1.6rem;
}

.what_you_get_section .collapsable .img_responsive {
  margin-top: 1.6rem;
  margin-bottom: 2rem;
}

.what_you_get_section .right_col .desc {
  font-size: var(--ft22);
  font-weight: var(--ft-wt400);
  color: var(--dark1);
  margin-bottom: 2rem;
}

.what_you_get_section .right_col img {
  margin-top: 1rem;
}

/* what_you_get_section css end*/

.our_value_section {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.our_value_section .main_heading {
  margin-bottom: 7rem;
}

.our_value_card_row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 3rem;
}

.our_value_card_col {
  width: calc(20% - 3rem);
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.our_value_card {
  background-color: var(--primary2);
  min-height: 29.2rem;
  border: 0.1rem solid var(--light1);
  width: 100%;
  border-radius: 1.6rem;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--ft20);
  font-weight: var(--ft-wt600);
  color: var(--primary);
  transition: 0.25s;
}

.our_value_card .card_img {
  height: 15.2rem;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  transition: 0.25s;
  translate: 0 0;
}

.our_value_card .card_img img {
  max-width: 100%;
}

.our_value_card .card-content {
  max-width: 19.7rem;
  text-align: center;
  transition: 0.25s;
  translate: 0 0;
}

.our_value_card .tooltip {
  visibility: hidden;
  /* opacity: 0; */
  transition: 0.25s;
  font-weight: var(--ft-wt400);
  font-size: var(--ft20);
  padding-top: 2rem;
  /* max-height: 0;
  overflow: hidden; */
}

.our_value_card:hover .tooltip {
  visibility: visible;
  /* opacity: 1; */
  /* max-height:20rem; */
}

.our_value_card:hover {
  background-color: var(--primary);
}

.our_value_card:hover {
  background-color: var(--primary);
  color: var(--white);
}

.our_value_card:hover .card_img {
  translate: 0 -40%;
}

.our_value_card:hover .card-content {
  translate: 0 -40%;
}

/* our_value_section css end*/

.minds_behind_section {
  background-color: var(--primary2);
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.minds_behind_section.our-advisors {
  background-color: var(--white);
  padding-bottom: 15rem;
}

.minds_behind_row {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  margin-top: 8rem;
  justify-content: space-between
}

.minds_behind_col {
  /* width: calc(25% - 2.25rem); */
  width: calc(33% - 2rem);
  font-size: var(--ft16);
  color: var(--gray3);
}

.minds_behind_col.swiper_card {
  width: 100%;
  margin-bottom: 2rem;
}

.minds_behind_col .avatar_wrapper {
  width: 12.4rem;
  height: 12.4rem;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 2.4rem;
}

.minds_behind_col .avatar_wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.minds_behind_col .avatar_name {
  font-size: var(--ft26);
  color: var(--dark1);
  font-weight: var(--ft-wt600);
  margin-bottom: 1rem;
}

.minds_behind_col .avatar_position {
  font-size: var(--ft16);
  color: var(--primary1);
  font-weight: var(--ft-wt600);
  margin-bottom: 1rem;
}

.minds_behind_col .social_icons_wrapper {
  margin-top: 2rem;
}

.minds_behind_col .social_icon {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 0.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.minds_behind_col .social_icon.linkedin {
  background-color: #1666C2;
}

.minds_behind_col .social_icon.linkedin img {
  max-width: 80%;
  max-height: 80%;
}

/* minds_behind_section end */
.we_are_hiring_card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border: 0.1rem solid var(--light1);
  /* margin-top: 12rem; */
  height: 36rem;
  border-radius: 1.6rem;
  padding-left: 1.5rem;
  align-items: center;
}

.we_are_hiring_card_img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: -3.5rem;
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.we_are_hiring_card_img img {
  max-width: 100%;
}

.we_are_hiring_card_img:before {
  content: '';
  display: block;
  width: 62rem;
  height: 52.1rem;
  border-radius: 50%;
  background: linear-gradient(270deg, rgba(231, 194, 255, 0.3) 0%, rgba(242, 222, 255, 0.3) 76.04%);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.12);
  z-index: -1;
  position: absolute;
  rotate: -26.31deg;
  translate: -20%;
}

.we_are_hiring_card_img:after {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  width: 48.1rem;
  height: 48.1rem;
  background: radial-gradient(49.9% 69.87% at 49.9% 50.1%, rgba(209, 137, 255, 0.64) 0%, rgba(218, 162, 255, 0.1472) 100%);
  filter: blur(50px);
  transform: rotate(-26.31deg);
  border-radius: 50%;
  translate: 10% -10%;
}

.we_are_hiring_card_content {
  font-size: 2.8rem;
  font-weight: var(--ft-wt500);
  max-width: 71.4rem;
  margin-left: 10rem;
}

.we_are_hiring_card_content .btn_dark {
  min-width: 34rem;
  min-height: 6.4rem;
  margin-top: 5.4rem;
}

/* we_are_hiring_card end */
@media screen and (min-width:1200px){

  /* solution section end */
  .ecosystem_solution .img_responsive{
    max-width: 65%;
    display: block;
    margin: auto;
  }
}
@media(max-width:1300px) {
  .solution_section .hover_card_title {
    font-size: var(--ft16);
    line-height: 120%;
  }
}

@media(max-width:1200px) {

  /* solution section end */
  .we_are_hiring_card_content {
    max-width: auto;
    flex: 1;
  }
}

@media(max-width:900px) {

  .dsk-show {
    display: none;
  }

  .mob-show {
    display: block;
  }

  .main_wrapper {
    padding-top: 6rem;
  }

  .row {
    padding: 2.4rem 0rem;
  }

  .main_heading {
    font-size: 2.8rem;
    margin-bottom: 4rem !important;
  }

  .left_col,
  .right_col {
    width: 100%;
  }

  /* header css */
  .site_header nav {
    flex-wrap: wrap;
    padding: 2.4rem 0rem;
  }

  .site_header .logo_sec {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .site_header .header_links_sec {
    flex-direction: column;
    width: 100%;
    align-items: center;
    width: 100%;
    display: flex;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: 0.25s ease-in;
    margin: 0 auto;
  }

  .site_header nav .link {
    margin-right: 0;
    margin-bottom: 3rem;
  }

  .site_header nav .link:first-child {
    margin-top: 8rem;
  }

  .site_header.active .header_links_sec {
    visibility: visible;
    opacity: 1;
    max-height: 100vh;
  }

  .site_header .toggler {
    display: inline-block;
  }

  .header_links_sec .btn_dark {
    min-width: 28rem;
    min-height: 5.4rem;
  }

  /* header css  end*/
  .hero_section {
    padding-bottom: 5rem;
  }

  .hero_section .left_col {
    order: 2;
    width: 100% !important;
  }

  .hero_section .right_col {
    order: 1;
    margin-top: 7rem;
    margin-bottom: 5rem;
    text-align: center;
  }

  .hero_section .title {
    font-size: var(--ft22);
    line-height: 3rem;
  }

  .hero_section .sub_title {
    font-size: var(--ft16);
    margin-bottom: 0;
  }


  /* hero_section css end */

  .problem_section {
    padding-bottom: 50px;
  }

  .problem_section .oval_div {
    text-align: center;
    padding-right: 0;
  }

  .problem_section .oval_div .problem_img {
    max-width: 80%;
  }

  .problem_section .title {
    font-size: var(--ft28);
    line-height: 3.6rem;
    margin-top: 5rem;
  }

  .problem_section .desc {
    font-size: var(--ft16);
  }

  .problem_section .oval_div::before {
    width: 115%;
    height: 110%;
    right: 10%;
  }

  /* problem section  end */
  .solution_section .bg_dots {
    width: 100%;
  }

  .solution_section .show_hover_card {
    margin-top: 3rem;
    min-height: 27rem;
    padding: 1.6rem
  }

  .solution_section .show_hover_card {
    font-size: var(--ft12);
    line-height: 1.6rem;
  }

  .solution_section .show_hover_card .card_title {
    font-size: var(--ft14);
    line-height: 2rem;
    padding: 0.6rem;
    border-left-width: 0.4rem;
  }

  .show_hover_card .icon_contianer {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }

  .show_hover_card .icon_contianer svg {
    width: 6rem;
    height: 6rem;
  }

  .solution_section .brand_row .left_col {
    width: calc(100% - 10rem);
    padding-right: 2rem
  }

  .solution_section .brand_row .right_col {
    width: 10rem;
  }

  .solution_section .title {
    font-size: var(--ft20);
    margin-bottom: 1rem
  }

  .solution_section .text {
    font-size: var(--ft16);
  }

  .solution_section .hover_card_wrapper {
    margin-top: 0;
  }

  .solution_section .hover_card {
    width: 10rem;
    min-height: 10rem;
    border-radius: 50%;
    padding: 1rem;
  }

  .solution_section .hover_card_title {
    font-size: var(--ft10);
    line-height: 1.3rem;
  }

  .solution_section .hover_card .icon_contianer svg {
    width: 3.2rem;
    height: 3.2rem;
  }

  .solution_section .distributor_row {
    margin-top: 5rem
  }

  .solution_section .distributor_row .left_col {
    width: 10rem;
  }

  .solution_section .distributor_row .right_col {
    width: calc(100% - 10rem);
    padding-left: 2rem
  }

  .solution_section .right_col .show_hover_card {
    margin-left: 0;
  }

  /* solution section  end */
  .ecosystem_solution {
    margin-top: 7rem;
  }

  /* .ecosystem_solution css end */
  .doco_part_card {
    flex-wrap: wrap;
    padding: 2rem 1.6rem;
    width: 100% !important;
  }

  .doco_part_card .doco_card_text_div {
    flex: unset;
    padding-left: 0;
  }

  .doco_part_card .card_title {
    font-size: var(--ft16);
    margin-bottom: 1.6rem;
    width: 100%;
  }

  .doco_part_card .doco_img_div img {
    width: 10rem;
  }

  .doco_part_card .card_list {
    font-size: var(--ft14);
    padding-left: 2rem
  }

  .doco_part_card .card_btn_div {
    margin-top: 2.4rem;
    margin-bottom: 1rem;
  }

  .doco_part_card .btn_dark {
    min-height: 4rem;
    min-width: 16.8rem;
    font-size: var(--ft14);
  }

  /* doco_part_section css end */
  .testimonials_section {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .testimonials_card {
    font-size: var(--ft14);
    min-height: 25rem;
  }

  .testimonials_card_wrap {
    padding-right: 1rem;
    margin-bottom: 2rem;
  }

  .testimonials_card .card_avatar_section h2 {
    font-size: var(--ft18);
  }

  .testimonials_card .avatar_img_div {
    width: 6.4rem;
    height: 6.4rem;
  }

  .testimonials_card .avatar_info {
    text-indent: 9rem;
  }

  /* testimonials section css end */

  .footer_sec_1,
  .footer_sec_2,
  .footer_sec_3,
  .footer_sec_4 {
    width: 100%;
    margin-bottom: 5rem;
  }

  .footer_text_copyright {
    flex: 1;
    margin-top: 0;
  }

  /* doco life page css start */

  .what_you_get_section .accordion_btn.dsk-show {
    display: none !important;
  }

  .what_you_get_section .accordion_btn.mob-show {
    display: flex !important;
  }

  .what_you_get_section .accordion_btn {
    background-color: var(--light1);
  }

  .what_you_get_section .accordion_btn .down_angle {
    display: inline-block;
  }

  .what_you_get_section .collapsable {
    transition: 0.25s ease-in-out;
    transition-delay: 0.25s;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    display: block;
  }

  .what_you_get_section .collapsable.active {
    visibility: visible;
    max-height: 200vh;
    opacity: 1;
  }

  /* what_you_get_section css end*/

  .our_value_card {
    min-height: 20.9rem;
    font-size: var(--ft14);
  }

  .our_value_card .card_img {
    height: 10.6rem;
    width: 9.7rem;
  }

  .our_value_card_row {
    gap: 1.6rem;
    align-items: unset;
  }

  .our_value_card_col {
    width: calc(50% - 0.8rem);
    gap: 1.6rem;
  }
  .our_value_card .tooltip {
    font-size: var(--ft14);
  }

  /* our value section css end */

  .minds_behind_row {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    gap: 4rem;
  }

  .minds_behind_col {
    width: 100%;
  }

  /* minds_behind_section end */
  .we_are_hiring_card {
    height: auto;
  }

  .we_are_hiring_card_img {
    width: 28rem;
    padding-right: 3rem;
    margin-right: auto;
  }

  .we_are_hiring_card_content {
    width: 100%;
    margin-left: 0;
    padding: 1.6rem;
    font-size: 2.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: unset;
  }

  .we_are_hiring_card_img:before {
    width: 48.5rem;
    height: 40.7rem;
  }

  .we_are_hiring_card_img:after {
    width: 37.7rem;
    height: 37.7rem;
  }

  .we_are_hiring_card_content .btn_dark {
    min-width: 28rem;
    margin-bottom: 8rem;
  }

}

@media(max-width:500px) {


  /* .solution_section .hover_card {
    width: calc(100%);
    font-size: 1rem;
  } */

  .ecosystem_solution .img_responsive {
    width: 100%
  }
}
